.appTappContent {
  opacity: 0;
  z-index: 0;
  height: 100%;
  display: none;

  &Active {
    opacity: 1;
    z-index: 10;
    display: block;
  }
}
