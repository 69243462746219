.leftBar {
    min-width: 62px;
    transition: width .3s ease;
    height: 100%;
    position: relative;
    z-index: 31; // calendar

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    user-select: none;



}
.dragging {
    transition: unset !important;
}

.bottomAlign, .topAlign {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.topAlign {
    margin-top: 4px;
}

.handle {
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    width: 1px;
    cursor:col-resize;

    &:after {
        position: absolute;
        content: '';
        right: -7px;
        left:-7px;
        top:0;
        bottom:0;
    }
}


.userImage{
    border-radius: 50%;
    width: 35px;
    height: 35px;
    border: 0.05px solid rgba(0,0,0,0.1);
}

.userImageWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    cursor: pointer;
}

.alignmentWrapper {
    display: flex;
    flex-direction: column;
}
